html {
  box-sizing: border-box;
  font-size: 62.5%;
}
*,
*:before,
*:after {
  box-sizing: inherit;
  outline: 0;
}
body {
  background: $body-bkgd;
  color: $body-font-color;
  font-family: $body-font-family !important;
  font-size: $global-font-size;
  line-height: $global-lineheight;
}
.row .row {
  margin: 0 auto;
}
.collapsed {
  .row .column {
    padding: 0 $global-padding;
  }
}
hr {
  border-color: $divider-color;
  max-width: 100%;
  margin: $global-vertical-space 0;
}
