section {
  display: block;
  padding: $global-padding-large 0;
}

.Section-grid {
  @include breakpoint(small-only) {
    padding: 0;
  }
  .rev-Row--flex {
    @include breakpoint(small-only) {
      display: inline-block;
    }
  }
}