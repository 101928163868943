$progress-radius: $global-rounded !default;
$progress-meter-height: $size-tiny !default;
$progress-meter-bkgd: $darker-gray !default;

@mixin progress-color-management {
  @for $i from 1 through length($color-list) {
    &--#{nth($color-list, $i)} {
      .rev-Progress-track-amount {
        background-color: nth($color-vars, $i);
      }
    }
  }
}

.rev-Progress {
  @include progress-color-management;
  margin: $global-vertical-space 0;
}

.rev-Progress-track {
  background: $divider-color;
  border-radius: $progress-radius;
  display: block;
  height: $progress-meter-height;
  position: relative;
  width: 100%;
}
.rev-Progress-track-amount {
  background-color: $progress-meter-bkgd;
  border-radius: $progress-radius;
  display: block;
  height: $progress-meter-height;
  left: 0;
  position: absolute;
}
.rev-Progress-text {

}
