@mixin icon-link {
  background: transparent;
  border: 0;
  color: $brand-secondary;
  font-family: $global-sans-serif-font;
  font-size: $global-font-size-small;
  font-weight: $global-weight-semibold;
  padding-left: 0;
  padding-right: 0;
  &:hover {
    color: darken($brand, 10%);
  }
  &--back {
    margin-bottom: $global-margin;
  }
  &--remove {
    color: $warning;
    &:hover {
      color: darken($warning, 10%);
    }
  }
  &--disabled {
    color: $gray;
    &:hover {
      color: darken($gray, 10%);
    }
  }
}

// Icon Size Mixin
@mixin icon-size($size: $feather-icon-size) {
  height: $size;
  width: $size;
}
