ul,
ol {
  margin: 0;
  padding: 0;
}
ul {
  list-style: none;
}
li {
  margin: 0;
  padding: $global-padding-small 0;
}
