.Footer {
  @include flex(center, column, center);
  border-top: 1px solid $divider-color;
  font-size: $global-font-size-smaller;
  padding: $global-padding-large 0;
  text-align: center;
  p {
    padding-bottom: 0;
    @include breakpoint(small-only) {
      padding: 0 $global-padding;
    }
  }
} 