// default component vars listed at the top of the component stylesheet
// those vars are transferred to rev-settings AND are commented out
// this way updates can be made to a component by uncommenting settings vars
$sticky-container-bkgd: $white 0 !default;
$sticky-bkgd: $white 0 !default;

.rev-Sticky {
  position: relative;
  background: $sticky-bkgd;
  padding: $global-padding;
  background-color: $primary;
  color: #fff;
}

.rev-Sticky-container {
  background: $sticky-container-bkgd;
  position: relative;
}

.rev-Sticky-wrapper {
  width: 100%;
}

.rev-Sticky-placeholder {
  display: none;
}

.rev-Sticky--stuck {
  position: fixed;
}
