// Flex Utils go here
// Copyright © Revelry Labs, LLC 2019 - All Rights Reserved.

.u-flexBaseline {
  @include flex(baseline);
}
.u-flexAlignCenter {
  @include flex(center, row, center);
}
.u-flexColumnAlignCenter {
  @include flex(flex-start, column, center);
}
.u-flexAlignStart {
  @include flex(center, row, flex-start);
}
.u-flexAlignSpaceBetween {
  @include flex(center, row, space-between);
}
.u-flexAlignEnd {
  @include flex(flex-end, row, flex-end);
}
.u-flexJustifyEnd {
  justify-content: flex-end;
}
.u-flexAlignCenter--mediumUp {
  @include breakpoint(medium) {
    @include flex(center, row, center);
  }
}
.u-flexAlignCenter--largeUp {
  @include breakpoint(large) {
    @include flex(center, row, center);
  }
}
.u-flexAlignStart--mediumUp {
  @include breakpoint(medium) {
    @include flex(center, row, flex-start);
  }
}
.u-flexAlignSpaceBetween--mediumUp {
  @include breakpoint(medium) {
    @include flex(center, row, space-between);
  }
}
.u-flexAlignEnd--mediumUp {
  @include breakpoint(medium) {
    @include flex(flex-end, row, flex-end);
  }
}
.u-flexColumnAlignEnd--mediumUp {
  @include breakpoint(medium) {
    @include flex(flex-end, column, flex-end);
  }
}
