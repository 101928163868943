// default component vars listed at the top of the component stylesheet
// those vars are transferred to rev-settings AND are commented out
// this way updates can be made to a component by uncommenting settings vars
$calendar-bkgd: $card-bkgd !default;
$calendar-border: $card-border !default;
$calendar-container-width: 32rem !default;
$calendar-width: 32rem !default;
$calendar-max-height-animation: 35rem !default;
$calendar-header-bkgd: $calendar-bkgd !default;
$calendar-header-height: $size-large !default;

.rev-DatePicker {
  display: block;
  position: relative;
/*   this enables the Chrome and Firefox designs to be the same
 */    
    ::-moz-placeholder {
      opacity: 1;
      color: black;
    }

    ::-webkit-input-placeholder {
      opacity: 1;
      color: black;
    }

    :-ms-input-placeholder {
      opacity: 1;
      color: black;
    }
}
.rev-Calendar {
  @include global-transition;
  overflow: hidden;
  position: relative;
  &.rev-Calendar--closed {
    max-height: 0px;
    border: none;
  }
  &.rev-Calendar--open {
    max-height: $calendar-max-height-animation;
  }
  &.rev-Calendar--overlay {
    position: absolute;
    top: calc(100% + #{$global-padding-small});
    z-index: $above-most;
    &.rev-Calendar--closed {
      opacity: 0;
      transform: translateY(-10px);
    }
    &.rev-Calendar--open {
      opacity: 1;
      transform: translateY(0px);
    }
    .rev-Calendar-header {
      @include arrow-up-outline($calendar-header-bkgd);
    }
  }
  .rev-Card {
    background: $calendar-bkgd;
    border: $calendar-border;
    margin-top: $global-padding-small;
    width: $calendar-container-width;
  }

  // DatePicker Calendar Header Row
  .rev-Calendar-header {
    @include flex(center, row, space-between, nowrap);
    background: $calendar-header-bkgd;
    height: $calendar-header-height;
    text-align: center;
    position: relative;
  }
  .rev-Calendar-header-button {
    @include button;
    border-radius: 0;
    margin: 0;
  }
  .rev-Calendar-header-button--previous {
    border-top-left-radius: $card-radius;
  }
  .rev-Calendar-header-button--next {
    border-top-right-radius: $card-radius;
  }

  .rev-Calender-year-selection {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 30%;
    height: 72%;
    justify-content: space-between;
  }
}
.rev-Card-header .rev-Button.rev-Button--small.rev-Calendar-year-selection-button {
  padding: 0;
  height: $size-small;
  width: $size-small;
  margin: 0;
  span {
    position: relative;
    bottom: 2px;
  }
}

.rev-InputHelpText,
.rev-InputErrors {
  + .rev-Calendar {
    margin-top: 0;
  }
}

// DatePicker Calendar
.rev-Calendar-body {
  margin-left: auto;
  margin-right: auto;
  max-width: $calendar-width;
  width: 100%;
}
.rev-Calendar-body-headerCell {
  text-align: center;
}
.rev-Calendar-body-bodyCell {
  text-align: center;
  padding-bottom: calc((#{100% / 7}) / 2);
  padding-top: calc((#{100% / 7}) / 2);
  position: relative;
  width: calc(100% / 7);
  & > button {
    appearance: none;
    -webkit-appearance: none;
    background: transparent;
    border: none;
    bottom: 0;
    color: inherit;
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
.rev-Calendar-body-headerCell {
  text-align: center;
  padding-bottom: calc((#{100% / 7} - #{1.5rem * $global-lineheight}) / 2);
  padding-top: calc((#{100% / 7} - #{1.5rem * $global-lineheight}) / 2);
  position: relative;
  width: calc(100% / 7);
}
.rev-DatePicker--custom {
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
  }
}
.rev-Calendar-body-bodyCell--otherMonth {
  color: $light-gray;
}
.rev-Calendar-body-bodyCell--thisMonth {

}
.rev-Calendar-body-bodyCell--selected.rev-Calendar-body-bodyCell--selected {
  background-color: $brand;
  color: $white;
}
.rev-Calendar-body-bodyCell--unselectable {
  color: $lighter-gray;
}


.rev-Calendar-body-bodyCell--highlighted {
  color: $success;
}
