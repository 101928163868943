.rev-Radio {
  @include input-style;
  background: transparent;
  box-shadow: none;
  display: inline-block;
  margin-bottom: $global-padding-tiny;
  margin-right: $global-padding-tiny;
  padding-bottom: $global-padding-small;
  padding-top: $global-padding-small;
  width: auto;
  &:focus,
  &:active {
    box-shadow: none;
  }
  &.rev-Radio--stacked {
    display: block;
    margin-right: 0;
  }
  input:disabled {
    @include disabled;
    +.rev-Radio-label {
      @include disabled;
    }
  }
}
.rev-Radio-label {
  @extend .rev-LabelText;
}
.rev-Radio-input {
  margin-right: $global-padding-small;
}
.rev-Radio,
.rev-Radio-input {
  ~ .rev-InputHelpText,
  ~ .rev-InputErrors {
    margin-top: -$global-padding-tiny;
  }
}
