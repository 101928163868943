$test: #F0F;

// BRAND COLORS
$brand: #27104E;
$brand-secondary: #64379F;
$brand-tertiary: #75E8E7;

$white: #FFF;
$lightest-gray: #F4F4F4;
$lighter-gray: #D5D5D5;
$light-gray: #ABABAB;
$gray: #818181;
$dark-gray: #565656;
$darker-gray: #2A2A2A;
$black: #000;

$white-0: rgba(255, 255, 255, 0); // for animation purposes
$white-03: rgba(255, 255, 255, 0.03);
$white-06: rgba(255, 255, 255, 0.06);
$white-10: rgba(255, 255, 255, 0.1);
$white-20: rgba(255, 255, 255, 0.2);
$white-30: rgba(255, 255, 255, 0.3);
$white-40: rgba(255, 255, 255, 0.4);
$white-50: rgba(255, 255, 255, 0.5);
$white-60: rgba(255, 255, 255, 0.6);
$white-70: rgba(255, 255, 255, 0.7);
$white-80: rgba(255, 255, 255, 0.8);
$white-90: rgba(255, 255, 255, 0.9);
$black-0: rgba(0, 0, 0, 0); // for animation purposes
$black-03: rgba(0, 0, 0, 0.03);
$black-06: rgba(0, 0, 0, 0.06);
$black-10: rgba(0, 0, 0, 0.1);
$black-20: rgba(0, 0, 0, 0.2);
$black-30: rgba(0, 0, 0, 0.3);
$black-40: rgba(0, 0, 0, 0.4);
$black-50: rgba(0, 0, 0, 0.5);
$black-60: rgba(0, 0, 0, 0.6);
$black-70: rgba(0, 0, 0, 0.7);
$black-80: rgba(0, 0, 0, 0.8);
$black-90: rgba(0, 0, 0, 0.9);

// UTILITY COLORS
$primary: $brand;
$success: #00A67F;
$alert: #D94011;
$warning: #D10034;
$error: #D10034;

$divider-color: $black-10;
$divider-color-dark: $black-20;
$divider-color-light: $white-10;
$muted: $lighter-gray;
$very-muted: $lightest-gray;

$disabled: $light-gray;
