
.Splash-header {
  @include flex(center, column, center);
  background-color: $white;
  background-image: url('/splash-bkgd.png');
  background-position: center;
  background-size: cover;
  color: $body-font-color;
  min-height: 100vh;
  @include breakpoint(small-only) {
    text-align: center;
  }
  h1 {
    @include breakpoint(small-only) {
      font-size: $global-font-size-larger;
      line-height: $global-lineheight;
    }
  }
  .rev-Row--flex {
    @include breakpoint(medium) {
      @include flex(center, row, center);
    }
  }
}
.Splash-logo {
  height: 20rem;
  margin: 0 auto;
  @include breakpoint(large) {
    height: 40rem;
  }
}