.rev-Checkbox {
  @include input-style;
  background: transparent;
  box-shadow: none;
  display: inline-block;
  margin-bottom: $global-padding-tiny;
  margin-right: $global-padding-tiny;
  padding-bottom: $global-padding-small;
  padding-top: $global-padding-small;
  width: auto;
  &:focus,
  &:active {
    box-shadow: none;
  }
  &.rev-Checkbox--stacked,
  &.rev-Radio--stacked {
    display: block;
    margin-right: 0;
  }
  input:disabled {
    @include disabled;
    +.rev-Checkbox-label {
      @include disabled;
    }
  }
}
.rev-Checkbox-label {
  @extend .rev-LabelText;
}
.rev-Checkbox-input {
  margin-right: $global-padding-small;
}
