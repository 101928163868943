//# TopBar
$logo-size-default: 3rem;
$feather-dropdown-size: 15px;

//# Cards
$card-endorsement-height: 15rem;
$card-endorsement-height-larger: 16rem;
$card-endorsement-height-largest: 20rem;
$card-endorsement-height-jumbo: 23rem;
$card-benefits-height: 26rem;
$card-overlay-width: 60rem;
$card-network-stats-height: 20rem;
$cardscroll-notifications-lineheight: 1.3;
$card-events-height: 19rem;

//# Gradients
$gradient-primary: linear-gradient($brand-secondary 0%, lighten($brand-secondary, 8%) 100%);

//# Badges
$notifications-badge-line-height: 0.6;

//# Icons
$feather-icon-size-tinier: 10px;
$feather-icon-size-tiny: 13px;
$feather-icon-size-smaller: 15px;
$feather-icon-size-small: 18px;
$feather-icon-size: 20px;
$feather-icon-size-large: 25px;
$feather-icon-size-larger: 30px;
$feather-icon-size-largest: 70px;
$feather-icon-size-jumbo: 100px;

//# Footer
$logo-width: 23rem;
$static-logo-width: 165px;

//# Empty State
$empty-state-icon-size: 9rem;
$empty-state-icon-size-smaller: 6rem;
