// MARGINS:
@mixin margin-class($position) {
  .u-margin#{$position}Large {
    margin-#{to-lower-case(#{$position})}: $global-margin-large;
  }

  .u-margin#{$position} {
    margin-#{to-lower-case(#{$position})}: $global-margin;
  }

  .u-margin#{$position}Small {
    margin-#{to-lower-case(#{$position})}: $global-margin-small;
  }

  .u-margin#{$position}Tiny {
    margin-#{to-lower-case(#{$position})}: $global-margin-tiny;
  }
}

@include margin-class('Bottom');
@include margin-class('Left');
@include margin-class('Right');
@include margin-class('Top');


.u-noMargin {
  margin: 0;
}
.u-noMarginBottom {
  margin-bottom: 0;
}
.u-noMarginTop {
  margin-top: 0;
}
.u-margin {
  margin: $global-margin 0;
}

.u-noPadding {
  padding: 0;
}
.u-noPaddingBottom {
  padding-bottom: 0;
}
.u-noPaddingTop {
  padding-top: 0;
}


// Margin Loop WORK IN PROGRESS - DO NOT DELETE

// $margins: (Top, Right, Bottom, Left);
// $amounts: (Tiny $global-margin-tiny), (Small $global-margin-small);
//
// @each $position in $margins {
//   @each $space in $amounts {
//     .u-margin#{$position(nth($space, 1)) {
//       margin-#{to-lower-case(#{$position})}: nth($space, 2);
//     }
//   }
// }

// OR THIS (need to create vars)

// $margins: (Top, Right, Bottom);
// $sizes: (Tiny, Small, Large);
// $amounts: ($global-margin-tiny, $global-margin-small, $global-margin-large);
//
// @each $position in $margins {
//   @each $size in $sizes {
//     @each $space in $amounts {
//       .u-margin#{$position$size} {
//         margin-#{to-lower-case(#{$position})}: $space;
//       }
//     }
//   }
// }
