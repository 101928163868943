@mixin rev-Col($col: 1, $sum: 12) {
  width: percentage($col/$sum);
}
@mixin rev-Col-push ($push, $sum: 12) {
  left: percentage($push/$sum);
  position: relative;
}
@mixin rev-Col-pull ($pull, $sum: 12) {
  right: percentage($pull/$sum);
  position: relative;
}
