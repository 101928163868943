.About {
  background-color: $white;
  background-image: url('/homepage-bkgd-bottom.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: $topbar-height;
  @include breakpoint(medium) {
    min-height: 90vh;
  }
  .About-wrapper {
    @include breakpoint(medium) {
      @include flex(center, row, center);
    }
  }
  .About-img {
    width: 100%;
    @include breakpoint(small-only) {
      margin-top: $global-margin;
    }
    &.Last {
      margin-top: $global-margin;
    }
  }
  .Icon-wrapper {
    text-align: left;
  }
}