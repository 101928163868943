.Homepage {
  h3 {
    @include breakpoint(small-only) {
      padding-top: $global-padding;
    }
  }
  .About-img {
    width: 100%;
    @include breakpoint(small-only) {
      margin-top: $global-margin;
    }
    &.Last {
      margin-top: $global-margin;
    }
  }
}

.Homepage-top {
  background-color: $white;
  background-image: url('/splash-bkgd.png');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  @include breakpoint(medium) {
    background-size: contain;
  }
}

.Homepage-banner {
  padding-top: $topbar-height * 2;
  @include breakpoint(small-only) {
    text-align: center;
  }
  @include breakpoint(medium) {
    min-height: 90vh;
  }
  &.rev-Row--flex {
    @include breakpoint(medium) {
      @include flex(center, row, center);
    }
  }
  h1 {
    @include breakpoint(small-only) {
      font-size: $global-font-size-large;
      line-height: $global-lineheight;
    }
  }
}

.Homepage-about {
  @include breakpoint(medium) {
    @include flex(center, row, center);
    padding-top: $padding-larger * 2;
  }
  .Homepage-img {
    padding: 0 $padding-large;
    width: 100%;
  }
}

.Homepage-bottom {
  background-color: $white;
  background-image: url('/homepage-bkgd-bottom.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @include breakpoint(medium) {
    background-size: contain;
  }
}