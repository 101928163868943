// ROWS (float and flex)
.rev-Row {
  display: block;
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  position: relative;
  width: 100%;
  &:before,
  &:after {
    content: '';
    clear: both;
    display: table;
  }
  // Collapse padding on direct child cols
  &.rev-Row--collapse,
  &.rev-Row--smallCollapse {
    > .rev-Col {
      padding-left: 0;
      padding-right: 0;
    }
  }
  // Reset padding on direct child cols
  &.rev-Row--uncollapse,
  &.rev-Row--smallUncollapse {
    > .rev-Col {
      padding-left: $grid-column-padding;
      padding-right: $grid-column-padding;
    }
  }
  @include breakpoint(medium)  {
    &.rev-Row--mediumCollapse {
      > .rev-Col {
        padding-left: 0;
        padding-right: 0;
      }
    }
    &.rev-Row--mediumUncollapse {
      > .rev-Col {
        padding-left: $grid-column-padding;
        padding-right: $grid-column-padding;
      }
    }
  }
  @include breakpoint(large)  {
    &.rev-Row--largeCollapse {
      > .rev-Col {
        padding-left: 0;
        padding-right: 0;
      }
    }
    &.rev-Row--largeUncollapse {
      > .rev-Col {
        padding-left: $grid-column-padding;
        padding-right: $grid-column-padding;
      }
    }
  }
  @include breakpoint(xlarge)  {
    &.rev-Row--xlargeCollapse {
      > .rev-Col {
        padding-left: 0;
        padding-right: 0;
      }
    }
    &.rev-Row--xlargeUncollapse {
      > .rev-Col {
        padding-left: $grid-column-padding;
        padding-right: $grid-column-padding;
      }
    }
  }
  @include breakpoint(xxlarge)  {
    &.rev-Row--xxlargeCollapse {
      > .rev-Col {
        padding-left: 0;
        padding-right: 0;
      }
    }
    &.rev-Row--xxlargeUncollapse {
      > .rev-Col {
        padding-left: $grid-column-padding;
        padding-right: $grid-column-padding;
      }
    }
  }
}

// COLUMNS (float and flex)
.rev-Col {
  padding: 0 $grid-column-padding;
  position: relative;
  width: 100%;
  // Collapse padding on cols
  &.rev-Col--collapse {
    padding-left: 0;
    padding-right: 0;
  }
  // Reset padding on cols
  &.rev-Col--uncollapse {
    padding: 0 $grid-column-padding;
  }
  @for $i from 1 through $grid-columns {
    &.rev-Col--small#{$i} {
      width: 100% / $grid-columns * $i;
    }
  }
  @include breakpoint(medium) {
    @for $i from 1 through $grid-columns {
      &.rev-Col--medium#{$i} {
        width: 100% / $grid-columns * $i;
      }
    }
    &.rev-Col--mediumCollapse {
      padding-left: 0;
      padding-right: 0;
    }
    &.rev-Col--mediumUncollapse {
      padding: 0 $grid-column-padding;
    }
  }
  @include breakpoint(large) {
    @for $i from 1 through $grid-columns {
      &.rev-Col--large#{$i} {
        width: 100% / $grid-columns * $i;
      }
    }
    &.rev-Col--largeCollapse {
      padding-left: 0;
      padding-right: 0;
    }
    &.rev-Col--largeUncollapse {
      padding: 0 $grid-column-padding;
    }
  }
  @include breakpoint(xlarge) {
    @for $i from 1 through $grid-columns {
      &.rev-Col--xlarge#{$i} {
        width: 100% / $grid-columns * $i;
      }
    }
    &.rev-Col--xlargeCollapse {
      padding-left: 0;
      padding-right: 0;
    }
    &.rev-Col--xlargeUncollapse {
      padding: 0 $grid-column-padding;
    }
  }
  @include breakpoint(xxlarge) {
    @for $i from 1 through $grid-columns {
      &.rev-Col--xxlarge#{$i} {
        width: 100% / $grid-columns * $i;
      }
    }
    &.rev-Col--xxlargeCollapse {
      padding-left: 0;
      padding-right: 0;
    }
    &.rev-Col--xxlargeUncollapse {
      padding: 0 $grid-column-padding;
    }
  }
}
