$drawer-expander-size: 30px;

.rev-Drawer {
  @include breakpoint(nav-only) {
    @include drawer-collapsible;
    @include drawer-overlay;
  }
  &.rev-Drawer--right {
    left: auto;
    right: 0;
    @include breakpoint(nav) {
      display: none;
    }
    &.rev-Drawer--open {
      .rev-Menu--horizontalLeft,
      .rev-Menu--horizontalRight {
        @include breakpoint(nav-only) {
          display: block;
        }
      }
      .rev-Drawer-closer {
        @include breakpoint(nav-only) {
          color: $brand;
          right: 0;
          top: 1%;
        }
      }
      .rev-Drawer-contents {
        border-left: 1px solid $divider-color;
        height: 100%;
        padding-top: $global-padding-large;
      }
    }
    .rev-Drawer-expander {
      color: $brand;
      font-size: $global-font-size-large;
      left: auto;
      padding-top: $global-padding-tiny;
      right: 2%;
      a {
        color: $brand;
      }
      .feather {
        @include icon-size;
      }
    }
  }
  .rev-Drawer-closer {
    transform: rotate(45deg);
    svg,
    .feather {
      @include icon-size($feather-icon-size-smaller);
    }
  }
  .rev-TopBar-item {
    a {
      color: $brand;
      &:hover,
      &:active {
        color: $brand;
      }
    }
    .rev-Menu {
      justify-content: flex-start;
      min-height: 100vh;
      padding: $global-padding-large 0;
    }
    .rev-Menu-item {
      border-bottom: 1px solid $white-20;
      font-size: $global-font-size-small;
      font-weight: $global-weight-light;
      padding: $global-padding-tiny 0;
      &:last-child {
        background: transparent;
        border-radius: $button-radius;
        box-shadow: 0 0 0 1px $white inset;
        margin: $global-margin;
        padding: 0 $global-padding;
        text-align: center;
        width: auto;
      }
    }
    .rev-Menu-item--text {
      background-color: transparent;
      border-bottom: 0;
      color: $white;
      font-size: $global-font-size;
      font-weight: $global-weight-normal;
      padding: $global-padding;
      padding-bottom: $global-padding-tiny;
      text-transform: uppercase;
    }
  }
}
