.rev-Card,
.rev-CardLayout {
  @include typography-contained;
  background-color: $card-bkgd;
  border: 0;
  box-shadow: $global-box-shadow;
  margin-bottom: $global-margin-large;
  .rev-Row {
    padding-left: $global-padding-small;
    padding-right: $global-padding-small;
  }
  .rev-Col {
    @include image-contained;
  }
  &.rev-Card--primary {
    border: 0;
    box-shadow: $global-box-shadow;
  }
  &.rev-Card--secondary {
    background: transparent;
    border: 1px solid $divider-color;
  }
  &.rev-Card--small {
    .rev-Row {
      padding-left: $global-padding-tiny;
      padding-right: $global-padding-tiny;
    }
    .rev-Col {
      padding-left: $global-padding-tiny;
      padding-right: $global-padding-tiny;
    }
    p:first-child {
      margin-top: $global-padding-small;
    }
    p:last-child {
      margin-bottom: $global-padding-small;
    }
  }
}

.rev-Card-slice,
.rev-CardLayout-bar,
.rev-Card-header,
.rev-Card-footer {
  padding: $card-padding;

}
.rev-Card-header {
  @include button-contained;
  background-color: $card-header-background;
  border-bottom: 1px solid $divider-color;
}
.rev-Card-body,
.rev-CardLayout-fill {

}
.rev-Card-footer {
  @include button-contained;
  background-color: $card-footer-background;
  border-top: 1px solid $divider-color;
}


// Events Card
.CardImage {
  &.rev-Card {
    height: 100%;
    padding: 0;
    p {
      padding-top: $global-padding-small;
    }
  }
  .CardImage-wrapper {
    @include flex(flex-start, column, space-between);
    height: 100%;
  }
  .CardImage-header {
    padding-bottom: 0;
  }
  .rev-Card-header {
    overflow: hidden;
    width: 100%;
    img {
      height: 40rem;
      object-fit: cover;
      width: 100%;
    }
  }
  .rev-Card-body {
    margin: auto;
  }
  .rev-Card-footer {
    border-top: 0;
    margin-top: auto;
    padding: $global-padding;
  }
}

.CardImage-smaller {
  .rev-Card-header {
    img {
      height: 25rem;
    }
  }
  .CardImage-subheader {
    padding-bottom: 0;
  }
}

// Card Image Left
.CardImageLeft {
  @include breakpoint(medium) {
    @include flex(center, row, space-between);
  }
  .rev-Card-header,
  .rev-Card-body {
    @include breakpoint(medium) {
      flex: 1;
    }
  }
  .IconLink {
    padding-bottom: $global-padding;
  }
}

// Connection Card
.CardConnection {
  padding: $global-padding;
  p {
    padding-top: 0;
  }
}

// Cards Set to Equal Height using Flex
.EqualHeightCards {
  @include flex(stretch, row, stretch);
  @include breakpoint(medium) {
    @include flex(stretch, row, stretch);
  }
  .rev-Card {
    flex: 1;
  }
}