// default component vars listed at the top of the component stylesheet
// those vars are transferred to rev-settings AND are commented out
// this way updates can be made to a component by uncommenting settings vars
$callout-bkgd: $white !default;
$callout-border-size: 1px !default;
$callout-border-color: $divider-color-dark !default;
$callout-color: $body-font-color !default;
$callout-padding: $global-vertical-space $global-horizontal-space !default;

.rev-Callout {
  @include color-management;
  @include typography-contained;
  background: $callout-bkgd;
  border: $callout-border-size solid $callout-border-color;
  color: $callout-color;
  margin-bottom: $global-margin;
  padding: 0;
  &.rev-Callout--primary {
    background: $callout-bkgd;
    box-shadow: $global-box-shadow;
    color: $callout-color;
  }
  &.rev-Callout--secondary {
    background: $divider-color-dark;
    color: $callout-color;
  }
}
