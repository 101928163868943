
.rev-TopBar {
  background: $topbar-bkgd;
  border: $topbar-border-size solid $topbar-border-color;
  position: fixed;
  width: 100%;
  z-index: $above-everything;
  nav {
    @include flex(center, row, space-between, no-wrap);
    height: $topbar-height;
    margin: 0 auto;
    max-width: $global-width;
    padding: $global-padding-tiny $global-padding;
    width: 100%;
    @include breakpoint(xlarge) {
      padding: 0 $global-padding-larger;
    }
  }
  .rev-TopBar-title {
    height: 100%;
  }
  .rev-Brand {
    img {
      height: 100%;
      width: $logo-size-default;
    }
  }
  .rev-Menu-item {
    font-size: $global-font-size-small;
  }  
  .Menu-button {
    @include global-transition;
    background: transparent;
    border-radius: $button-radius;
    box-shadow: 0 0 0 1px $brand-secondary inset;
    color: $brand-secondary;
    margin-left: $global-margin-small;
    &:hover {
      background: $brand;
      box-shadow: 0 0 0 1px $brand inset;
      color: $white;
    }
    &:active,
    &:focus {
      background: $brand;
      box-shadow: 0 0 0 1px $brand inset;
      color: $white;
    }
    a {
      padding: $global-padding-tiny $global-padding-small !important;
      &:hover {
        color: $white !important;
      }
    }
  } 
  // SEARCH FORMS IN TOPBARS
  .rev-Form {
    padding-left: $grid-column-padding;
    padding-right: $grid-column-padding;
    .rev-Button,
    .rev-Input {
      margin: 0;
    }
  }
  .rev-InputGroup {
    .rev-Input {
      max-width: $topbar-input-width;
    }
  }
  // TOPBAR POSITIONING
  &.rev-TopBar--center {
    nav {
      @include flex(center, row, center, no-wrap);
    }
  }
  &.rev-TopBar--left {
    nav {
      @include flex(center, row, flex-start, no-wrap);
    }
  }
  &.rev-TopBar--justified { //default
    nav {
      @include flex(center, row, space-between, no-wrap);
    }
  }
  &.rev-TopBar--right {
    nav {
      @include flex(center, row, flex-end, no-wrap);
    }
  }
  // FIXED POSITION TOPBAR
  &.rev-TopBar--fixed {
    height: $topbar-height;
    left: 0;
    margin: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index:  $above-everything;
    .rev-Row {
      @include flex(stretch, row, space-between);
    }
  }
  .rev-Menu  {
    background: transparent;
    overflow: visible; // So that dropdowns can be visible 
    .rev-Menu-item a {
      border-radius: $global-radius;
    }
  }
}

// can override positioning placed on the parent .rev-TopBar
/*.rev-TopBar-item--right {
  margin-left: auto;
}
.rev-TopBar-item--left {
  margin-right: auto;
}*/

// SCROLLING CONTAINERS WHEN THERES TOO MANY NAV ITEMS 
.rev-TopBar-item--scroll {
  overflow-x: scroll;
}

$scrollBreakpoints: (smallOnly small-only),
                    (medium medium),
                    (large large),
                    (xLarge xlarge),
                    (mediumDown medium-down),
                    (largeDown large-down),
                    (xLargeDown xlarge-down),
                    (nav nav),
                    (navOnly nav-only);

@each $breakpoint in $scrollBreakpoints {
  .rev-TopBar-item--scroll--#{nth($breakpoint, 1)} {
    @include breakpoint(#{nth($breakpoint, 2)}) {
      overflow-x: scroll;
    }
  }
}
