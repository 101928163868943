.CaseStudy {
  padding-top: $topbar-height;
  @include breakpoint(medium) {
    min-height: 90vh;
  }
  a {
    text-decoration: underline;
  }
  p {
    max-width: 100%;
  }
  h5 {
    padding-bottom: $global-padding-tiny;
    padding-top: $global-margin-small;
    text-transform: none;
  }
  .CaseStudy-header {
    padding-bottom: 0;
    padding-top: $global-padding-small;
  }
  .CaseStudy-subheader {
    padding-top: 0;
  }
  .CaseStudy-sectionHeader {
    font-size: $global-font-size-large;
    padding-bottom: $global-font-size-small;
    padding-top: $global-padding-large;
  }
  .CaseStudy-img {
    box-shadow: $global-box-shadow;
    margin: $global-margin 0;
    width: 100%;
    &.NoBorder {
      box-shadow: none;
    }
    &.Smaller {
      margin: $global-margin auto;
      width: 50%;
    }
  }
  .CaseStudy-list {
    list-style: disc;
    li {
      margin-left: $global-margin-large;
    }
  }
  .CaseStudy-disclaimer {
    font-style: italic;
  }
}

.CaseStudy-tools {
  @include flex(center, column, center);
  height: 11rem;
  padding: $global-padding-small;
  img {
    max-width: 85%;
    width: 100%;
  }
}